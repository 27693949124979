<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='48'>
      <a-col :sm='12' :md='12' :xl='3' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">实时商品折扣</div>
          <div class="card_title" style="height: 10px;"></div>
          <div class="card_title" style="height: 26px;">31 : {{ dashboard.wealthBtcfQuotaAmount }}</div>
          <div class="card_title" style="height: 26px;">41 : {{dashboard.wealthQuotaAmount}}</div>
          <div class="card_title" style="height: 26px;">51 : {{dashboard.wealthZxQuotaAmount}}</div>
          <div class="card_title" style="height: 129px;">71 : {{dashboard.wealthBatchQuotaAmount}}</div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='12' :xl='3' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">查询商品折扣</div>
          <div class="card_title" style="height: 10px;"></div>
          <div class="card_title" style="height: 26px;">31 :  {{ dashboard.queryWealthBtcfQuotaAmount }}</div>
          <div class="card_title" style="height: 26px;">41 :  {{ dashboard.queryWealthQuotaAmount }}</div>
          <div class="card_title" style="height: 26px;">51 :  {{ dashboard.queryWealthZxQuotaAmount }}</div>
          <div class="card_title" style="height: 129px;">71 : {{ dashboard.queryWealthBatchQuotaAmount }}</div>
        </a-card>
      </a-col>

      <a-col :sm='24' :md='24' :xl='9' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_content">
              <a-row>
                <a-col :span="24"><span class="card_title color-red">获得总数：{{dashboard.getAll}}</span></a-col>
              </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">订单2获得：+{{ dashboard.quotaAmountGetByEndTag2 }}</span></a-col>
              <a-col :span="6"><span class="card_title">订单3获得：+{{ dashboard.quotaAmountGetByEndTag3 }}</span></a-col>
              <a-col :span="6"><span class="card_title">订单5获得：+{{ dashboard.quotaAmountGetByEndTag5 }}</span></a-col>
              <a-col :span="6"><span class="card_title">录订货单：+{{ dashboard.quotaAmountGetByEndTag7 }}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31修改获得：+{{ dashboard.btcfQuotaAmountGetUpdate }}</span></a-col>
              <a-col :span="6"><span class="card_title">41修改获得：+{{ dashboard.cfQuotaAmountGetUpdate }}</span></a-col>
              <a-col :span="6"><span class="card_title">51修改获得：+{{ dashboard.zxQuotaAmountGetUpdate }}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31取删返还：+{{ dashboard.btcfQuotaAmountReturn }}</span></a-col>
              <a-col :span="6"><span class="card_title">41取删返还：+{{dashboard.cfQuotaAmountReturn}}</span></a-col>
              <a-col :span="6"><span class="card_title">51取删返还：+{{dashboard.zxQuotaAmountReturn}}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title"></span></a-col>
              <a-col :span="6"><span class="card_title">门店补贴：+{{dashboard.cfQuotaAmountSubsidy}}</span></a-col>
              <a-col :span="6"><span class="card_title">订货51奖励：+{{dashboard.zxQuotaAmountAdd}}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title"></span></a-col>
              <a-col :span="6"><span class="card_title">订货41奖励：+{{dashboard.cfQuotaAmountAdd}}</span></a-col>
            </a-row>
          </div>
          <div class="card_content" >
            <a-row >
              <a-col :span="6"><span class=" color-red">{{dashboard.get31All}}</span></a-col>
              <a-col :span="6"><span class=" color-red">{{dashboard.get41All}}</span></a-col>
              <a-col :span="6"><span class=" color-red">{{dashboard.get51All}}</span></a-col>
              <a-col :span="6"><span class=" color-red">{{dashboard.get71All}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>


      <a-col :sm='24' :md='24' :xl='9' :style="{ marginBottom: '18px'}" >
        <a-card>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title color-red">使用总数：{{dashboard.useAll}}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31使用：{{ dashboard.quotaAmountUseByEndTag2 }}</span></a-col>
              <a-col :span="6"><span class="card_title">41使用：{{ dashboard.quotaAmountUseByEndTag3 }}</span></a-col>
              <a-col :span="6"><span class="card_title">51使用：{{ dashboard.quotaAmountUseByEndTag5 }}</span></a-col>
              <a-col :span="6"><span class="card_title">订货单：{{ dashboard.quotaAmountUseByEndTag7 }}</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31修改使用：{{ dashboard.btcfQuotaAmountUseUpdate }}</span></a-col>
              <a-col :span="6"><span class="card_title">41修改使用：{{ dashboard.cfQuotaAmountUseUpdate }}</span></a-col>
              <a-col :span="6"><span class="card_title">51修改使用：{{ dashboard.zxQuotaAmountUseUpdate }}</span></a-col>
              <a-col :span="6"><span class="card_title"></span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31转让：{{dashboard.btcfQuotaAmountTransferFrom}}</span></a-col>
              <a-col :span="6"><span class="card_title">41转让：{{dashboard.cfQuotaAmountTransferFrom}}</span></a-col>
              <a-col :span="6"><span class="card_title">51转让：{{dashboard.zxQuotaAmountTransferFrom}}</span></a-col>
              <a-col :span="6"><span class="card_title"></span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31受让：{{dashboard.btcfQuotaAmountTransferTo}}</span></a-col>
              <a-col :span="6"><span class="card_title">41受让：{{dashboard.cfQuotaAmountTransferTo}}</span></a-col>
              <a-col :span="6"><span class="card_title">51受让：{{dashboard.zxQuotaAmountTransferTo}}</span></a-col>
              <a-col :span="6"><span class="card_title"></span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="6"><span class="card_title">31商户删除：{{ dashboard.btcfQuotaAmountDeleteMerchant }}</span></a-col>
              <a-col :span="6"><span class="card_title">41商户删除：{{ dashboard.cfQuotaAmountDeleteMerchant }}</span></a-col>
              <a-col :span="6"><span class="card_title">51商户删除：{{ dashboard.zxQuotaAmountDeleteMerchant }}</span></a-col>
              <a-col :span="6"><span class="card_title">71商户删除：{{ dashboard.batchQuotaAmountDeleteMerchant }}</span></a-col>
            </a-row>
          </div>
          <div class="card_content" style="height: 31px;">
            <a-row >
              <a-col :span="6"><span class=" color-red">{{dashboard.use31All}}</span></a-col>
              <a-col :span="6"><span class=" color-red">{{dashboard.use41All}}</span></a-col>
              <a-col :span="6"><span class=" color-red">{{dashboard.use51All}}</span></a-col>
              <a-col :span="6"><span class=" color-red">{{dashboard.use71All}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>

    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='type'>
        <a-select v-model="form.business" placeholder="分类" style="width: 200px" >
          <a-select-option :value="1">
            获得
          </a-select-option>
          <a-select-option :value="2">
            使用
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='hType' v-if="this.form.business===1">
        <a-select  v-model="form.recordType" placeholder="变动类型" style="width: 200px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
          <a-select-option :value="1">
            合成
          </a-select-option>
          <a-select-option :value="3">
            修改
          </a-select-option>
          <a-select-option :value="4">
            累计贡献值
          </a-select-option>
          <a-select-option :value="7">
            取删返还
          </a-select-option>
          <a-select-option :value="9">
            店铺补贴
          </a-select-option>
          <a-select-option :value="1241">
            订货41奖励
          </a-select-option>
          <a-select-option :value="1251">
            订货51奖励
          </a-select-option>
          <a-select-option :value="1271">
            录订货单
          </a-select-option>
          <a-select-option :value="11">
            受让
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='recordType' v-if="this.form.business===2">
        <a-select v-model="form.recordType" placeholder="变动类型" style="width: 200px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
          <a-select-option :value="2">
            使用
          </a-select-option>
          <a-select-option :value="3">
            修改
          </a-select-option>
          <a-select-option :value="10">
            转让
          </a-select-option>
          <a-select-option :value="13">
           删除商户
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='ming'>
        <a-select v-model="form.quotaType" placeholder="名额类型" style="width: 200px">
          <a-select-option :value="undefined">
            名额类型
          </a-select-option>
          <a-select-option :value="3">
            31
          </a-select-option>
          <a-select-option :value="1">
            41
          </a-select-option>
          <a-select-option :value="2">
            51
          </a-select-option>
          <a-select-option :value="4">
            71
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='memberType'>
        <a-select v-model="form.memberType" placeholder="折扣类型" style="width: 200px">
          <a-select-option :value="undefined">
            折扣类型
          </a-select-option>
          <a-select-option :value="1">
            100%
          </a-select-option>
          <a-select-option :value="3">
            65%
          </a-select-option>
          <a-select-option :value="4">
            55%
          </a-select-option>
          <a-select-option :value="5">
            45%
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 200px">
          <a-select-option value="-1">
            全部
          </a-select-option>
          <a-select-option v-for="i in 8" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 200px" placeholder="姓名/手机" />
      </a-form-model-item>

      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-list>
          <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)' style="margin-right: 30px;">刷新</a-button>
          <span style="margin-right: 20px;">笔数：{{this.total}}</span>
          <span style="margin-right: 20px;">变动值：{{this.ext4}}</span>
        </a-list>

      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='memberType' slot-scope='text, record'>
          <template>
<!--            {{record}}-->
            {{handelMemberType(text)}}
          </template>
        </span>
        <span slot='quotaType' slot-scope='text, record'>
          <template>
            {{handelQuotaType(text)}}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
          {{showTypeText(record)}}
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <a-popconfirm title="是否要清除此行？" @confirm="handleDelete(record)">
            <a>清除</a>
          </a-popconfirm>
        </span>
      </s-table>
    </div>

  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryDiscount,queryDiscountHeard } from '@/api/member-order'
import moment from 'moment'


const columns = [
  {
    width: 150,
    title: '名额类型',
    dataIndex: 'quotaType',
    scopedSlots: { customRender: 'quotaType' }
  },
  {
    width: 150,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 150,
    title: '变动值',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 150,
    title: '变动后数值',
    dataIndex: 'afterAmount',
    scopedSlots: { customRender: 'afterAmount' }
  },
  {
    width: 150,
    title: '折扣类型',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberType' }
  },
  {
    width: 100,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '备注',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' }
  },
    {
    width: 180,
    title: '姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 100,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'AuditOrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      topLoading:true,
      showType:0,
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        business:1,
        keyword: "",
        auditStatus: 1,
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      total:0,
      ext4:0,
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        let param = Object.assign(parameter, {}, this.form);
        if(param.endTag==='-1'){
          delete param.endTag;
        }
        // debugger;
        return queryDiscount(param).then(datum => {
          this.total = datum.total;
          this.ext4 = datum.ext4;
            return datum
          })
      },
      merchantList: [],
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    moment,
    showTypeText(record){
      let text ="";
      if(record.business===3||record.business===9||record.business===13
        ||record.business===12||record.business===10||record.business===11){
        text = record.opUsername;
      } else{
        text = record.orderId;
      }
      // text += "----" +record.business+"--"+record.quotaType;
      return text;

    },
    handelMemberType(text){
      if(text==='normal'){
        return "100";
      } else if(text==='store'){
        return "65%";
      } else if(text==='center') {
        return "55%";
      } else if(text==='president') {
        return "45%";
      }
    },
    handelQuotaType(text){
      if(text===1){
        return "41";
      } else if(text===2){
        return "51";
      } else if(text===3){
        return "31";
      } else if(text===4) {
        return "71";
      }else{
        return "其它";
      }
    },
    onChangeType() {
      this.$set(this.deliver1.form, "extraRewardMobile", "");
      this.$set(this.deliver1.form, "extraRewardName", "");
      this.$set(this.deliver1.form, "extraRewardMerchantName", "");
      this.$set(this.deliver1.form, "extraRewardAuthCode", "");
      this.merchantList = [];
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      let param = Object.assign({}, this.form);
      if(param.endTag==='-1'){
        delete param.endTag;
      }
      console.log(param);
      queryDiscountHeard(param).then(result => {
        result.get31All = result.quotaAmountGetByEndTag2+result.btcfQuotaAmountGetUpdate+result.btcfQuotaAmountReturn;
        result.get41All = result.quotaAmountGetByEndTag3+result.cfQuotaAmountGetUpdate+result.cfQuotaAmountReturn+result.cfQuotaAmountSubsidy+result.cfQuotaAmountAdd;//
        result.get51All = result.quotaAmountGetByEndTag5+result.zxQuotaAmountGetUpdate+result.zxQuotaAmountReturn+result.zxQuotaAmountAdd;
        result.get71All = result.quotaAmountGetByEndTag7;

        let tempAll = result.get31All+result.get41All+result.get51All+result.get71All
        // debugger;
        result.getAll =  tempAll - result.btcfQuotaAmountReturn - result.cfQuotaAmountReturn - result.zxQuotaAmountReturn;


        result.use31All = result.quotaAmountUseByEndTag2+result.btcfQuotaAmountUseUpdate+result.btcfQuotaAmountDeleteMerchant;
        result.use41All = result.quotaAmountUseByEndTag3+result.cfQuotaAmountUseUpdate+result.cfQuotaAmountDeleteMerchant;
        result.use51All = result.quotaAmountUseByEndTag5+result.zxQuotaAmountUseUpdate+result.zxQuotaAmountDeleteMerchant;
        result.use71All = result.quotaAmountUseByEndTag7+result.batchQuotaAmountDeleteMerchant;
        // result.useDelAll = result.btcfQuotaAmountDeleteMerchant+result.cfQuotaAmountDeleteMerchant+result.zxQuotaAmountDeleteMerchant+result.batchQuotaAmountDeleteMerchant;

        result.useAll = result.use31All+result.use41All+result.use51All+result.use71All;

        this.dashboard = result;
      });
    },
    getAuthCodeList(authCode) {
        if (authCode) {
          let index = authCode.indexOf("-");
          if (index < 0) {
            index = authCode.length - 1;
          } else {
            index--;
          }
          let ownAuthCode = authCode.slice(index - 3, index + 1);
          if (ownAuthCode) {
            const authCodeList = authCode.split(ownAuthCode);
            authCodeList.splice(1, 0, ownAuthCode);
            return authCodeList;
          }
        }
        return [];
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getDataByMonth(tabs){
      let start = "";
      let end = "";
      if(tabs === "1"){
        //本月
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }else{
        //上月
        let lastMonth = this.getLastMonth();
        let monthYear = lastMonth.split("-");
        let year = monthYear[0];
        let month = monthYear[1];
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }
      end = end + " 23:59";
      start = start + " 00:00";
      let arr={};
      arr.start=start;
      arr.end =end;
      return arr;
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 16px 24px 10px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    display: flex;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
  .card_content_title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
.order_count .card_content {
  width: 50%;
}
</style>
